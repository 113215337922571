<template>
    <div>
        <div v-if="item" class="w-full h-0 overflow-hidden relative">
            <span ref="text" :class="classNames" v-html="item"></span>
        </div>
        <vue-marquee-slider :id="nome" :space="50" :speed="width*18.52" :width="width">
            <span v-if="!noRender" :class="classNames">{{ item }}</span>
            <slot />
        </vue-marquee-slider>
    </div>
  </template>
  
  <script setup>
    import {ref } from 'vue'
    import { VueMarqueeSlider } from 'vue3-marquee-slider';
    import { useElementBounding } from '@vueuse/core';
    const text = ref(null)
    const { width } = useElementBounding(text)
  
    const props = defineProps({
        item: String,
        nome: String,
        itemWidth: Number,
        noRender: Boolean,
        classes: String
    })

    const classNames = props.classes ?? 'text-lg md:text-xl lg:text-2xl px-4 sm:px-6 md:px-8 lg:px-10 uppercase'
  </script>
  
  <style scoped>
  </style>
  
<script>
    import { useWindowSize } from '@vueuse/core';
    
    import 'tw-elements';
    import SwiperMobile from './Mine/2024/SwiperMobile.vue';
    import HorizontalMarquee from './Mine/HorizontalMarquee.vue';

    export default {
        data(){
            return {
                windowSize: useWindowSize(),
                
                mobileMenu: false,
                textoLogo: false,

                tamanho: 'xs',

                edicoes: {
                    width: 150,
                    height: 232
                }
            }
        },

        components: {
            SwiperMobile,
            HorizontalMarquee
        },

        created(){
            this.mudaTamanho(this.windowSize.width);
            this.imgTamanhoEdicoes(this.windowSize.width);
        },

        mounted(){
            var este = this;
            window.addEventListener('resize', function(event) {
                este.mudaTamanho(este.windowSize.width);
                este.imgTamanhoEdicoes(este.windowSize.width);
            }, true);
        },

        methods: {
            entraLogoHover(){
                this.textoLogo = true
            },
            saiLogoHover(){
                this.textoLogo = false
            },
            toggleMenu(){
                this.mobileMenu = !this.mobileMenu;
                if(this.mobileMenu) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'auto';
                }
            },
            mudaTamanho(width){
                if(width >= 1280){
                    this.tamanho = 'xl';
                } else if(width >= 1024) {
                    this.tamanho = 'lg';
                } else if(width >= 768) {
                    this.tamanho = 'md';
                } else if(width >= 640) {
                    this.tamanho = 'sm';
                } else {
                    this.tamanho = 'xs';
                }
            },
            getPadding(width, height){
                return Math.ceil((height * 100) / width);
            },
            imgTamanhoEdicoes(windowWidth){
                this.edicoes.width = 150;
                this.edicoes.height = 232;
                if(windowWidth >= 1280){
                    this.edicoes.width = 285;
                    this.edicoes.height = 442;
                } else if(windowWidth >= 1024) {
                    this.edicoes.width = 183;
                    this.edicoes.height = 283;
                } else if(windowWidth >= 768) {
                    this.edicoes.width = 225;
                    this.edicoes.height = 349;
                } else if(windowWidth >= 640) {
                    this.edicoes.width = 221;
                    this.edicoes.height = 342;
                }
            }
        }
    }
</script>
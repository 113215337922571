// import axios from 'axios';
// window.axios = axios;

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Import modules...
import { createApp } from 'vue/dist/vue.esm-bundler.js';
import Front2024 from './Front2024.vue'
import { VueMarqueeSlider } from 'vue3-marquee-slider';
import '../../node_modules/vue3-marquee-slider/dist/style.css';

import VLazyImage from "v-lazy-image";

createApp(Front2024).component('v-lazy-image', VLazyImage).component('vue-marquee-slider', VueMarqueeSlider).mount("#app")

<template>
    <swiper
        slidesPerView="auto"
        :spaceBetween="20"
        :centeredSlides="true"
        :loop="true"
        :autoplay="true"
        :slidesOffsetAfter="20"
        :modules="modules"
        :slideToClickedSlide="true"
        class="mySwiper"
    >
      <swiper-slide v-for="slide in slides" :style="'width:' + largura">
        <div class="img-wrapper bg-cover" :style="'padding-top: ' + pt + '%; background-image:url(' + slide.img + '.' + webp + ')'"></div>
      </swiper-slide>
    </swiper>
</template>
<script>
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import 'swiper/css';
    import { Autoplay } from 'swiper';

    export default {
        props: ['slides','tamanho','pt','webp'],
        data(){
            return {
                largura: 0
            }
        },

        created(){
            this.mudaTamanho(this.tamanho);
        },

        components: {
            Swiper,
            SwiperSlide
        },
        setup() {
            return {
                modules: [Autoplay],
            };
        },

        watch: {
            tamanho() {
                this.mudaTamanho(this.tamanho);
            }
        },

        methods: {
            mudaTamanho(tamanho){
                if(1>2 && tamanho != 'md'){
                    this.largura = 'calc(100% - 80px)';
                } else {
                    this.largura = '50%';
                }
            }
        }
    };
</script>